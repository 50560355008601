import React, { useMemo, useState } from "react";
import withPreview from "../../utility/with-preview";
import { graphql } from "gatsby";
import classNames from "classnames";
import HubProdottoCard from "../elements/hub-prodotto-card";

const HubProdottoTab = ({ selectedCommodity, setSelectedCommodity, tab, isInTheMiddle }) => {
  return (
    <button
      name={tab.type}
      className={classNames("hub-prodotto__switch-button", {
        "hub-prodotto__switch-button--active": selectedCommodity === tab.type,
        "hub-prodotto__switch-button--no-side-border": isInTheMiddle,
      })}
      onClick={() => {
        setSelectedCommodity(tab.type);
      }}
    >
      {tab.label}
    </button>
  );
};

const HubProdotto = ({
  data,
  commodityFromProcessiInself,
  clienteFromProcessiInself,
  fornituraFromProcessiInself,
  selectedProduct,
  hideLabelTutelato,
}) => {
  const { moduleTitle, dualTab, gasTab, eleTab, card } = data;

  const tabs = useMemo(() => {
    function existCardType(cardType) {
      let find = false;

      card?.forEach((element) => {
        if (
          element?.content?.tab?.value === cardType ||
          element?.content?.tab?.value === "dualTab"
        ) {
          find = true;
        }
      });

      return find;
    }

    const labelsWithOrder = [
      {
        type: "dualTab",
        label: dualTab?.value || "",
        order: dualTab?.content?.dualTabPosition?.value,
        isThereCard: existCardType("dualTab"),
      },
      {
        type: "gasTab",
        label: gasTab?.value || "",
        order: gasTab?.content?.gasTabPosition?.value,
        isThereCard: existCardType("gasTab"),
      },
      {
        type: "eleTab",
        label: eleTab?.value || "",
        order: eleTab?.content?.eleTabPosition?.value,
        isThereCard: existCardType("eleTab"),
      },
    ];

    return labelsWithOrder.filter((item) => item.isThereCard).sort((a, b) => a.order - b.order);
  }, [dualTab, gasTab, eleTab, card]);

  const [selectedCommodity, setSelectedCommodity] = useState(
    commodityFromProcessiInself || tabs?.[0]?.type
  );

  return (
    <section className="section hub-prodotto wrapper-container px-0">
      <div className="container-fluid">
        {moduleTitle?.value && <h2 className="hub-prodotto__module-title">{moduleTitle.value}</h2>}
        {/* Non valorizzando le 3 label, il componente si può utilizzare in modalità singola card 
        e quindi non come hub */}
        {tabs?.length > 0 &&
          tabs[0]?.label !== "" &&
          tabs[1]?.label !== "" &&
          tabs[2]?.label !== "" && (
            <div className="hub-prodotto__switch">
              {tabs.map((tab, index) => (
                <HubProdottoTab
                  selectedCommodity={selectedCommodity}
                  setSelectedCommodity={setSelectedCommodity}
                  tab={tab}
                  isInTheMiddle={tabs.length > 2 ? (index === 1 ? true : false) : false}
                  key={index}
                />
              ))}
            </div>
          )}
        {card && (
          <div className="row px-md-3">
            <div className="col-12">
              {card
                .filter((singleCard) =>
                  singleCard?.content?.tab?.value === "dualTab"
                    ? true
                    : singleCard?.content?.tab?.value === selectedCommodity
                )
                .map((item, i) => (
                  <div
                    key={i}
                    className={classNames("hub-prodotto__promotion", {
                      "hub-prodotto__promotion--remove-mt": commodityFromProcessiInself,
                      "hub-prodotto__promotion--true": item?.content?.promotion?.value,
                    })}
                  >
                    {item?.content?.promotion?.value && (
                      <div className="hub-prodotto__promotion-text">OFFERTA IN PROMOZIONE</div>
                    )}
                    <HubProdottoCard
                      item={item?.content}
                      commodity={selectedCommodity}
                      target={commodityFromProcessiInself ? "_blank" : "_self"}
                      hideLabelTutelato={hideLabelTutelato}
                      clienteFromProcessiInself={clienteFromProcessiInself}
                      fornituraFromProcessiInself={fornituraFromProcessiInself}
                      selectedProduct={selectedProduct}
                    />
                  </div>
                ))}
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default withPreview(HubProdotto);
export const fragment = graphql`
  fragment JskHubProdottoFragment on LiferayJskHubProdotto {
    liferayFields {
      siteId
      articleId
    }
    moduleTitle {
      value
    }
    dualTab {
      value
      content {
        dualTabPosition {
          value
        }
      }
    }
    gasTab {
      value
      content {
        gasTabPosition {
          value
        }
      }
    }
    eleTab {
      value
      content {
        eleTabPosition {
          value
        }
      }
    }
    card {
      content {
        prodottoCRM {
          dataFineValidita
          corrispettivoEnergia
          corrispettivoF1
          corrispettivoF2
          corrispettivoF3
          componentePrezzoFisso
          spreadF1
          spreadF2
          spreadF3
          corrispettivoBiorariaF1
          corrispettivoBiorariaF23
          quotaCommFissaDomesticoGas
          quotaCommFissaDomesticoLuce
          quotaCommVariabileDomesticoGas
          quotaCommVariabileDomesticoLuce
          quotaPvolSmc
          quotaPvolkWh
          quotaPvolF1kWh
          quotaPvolF2kWh
          quotaPvolF3kWh
          quotaAlfakWh
          quotaAlfaF1kWh
          quotaAlfaF2kWh
          quotaAlfaF3kWh
          spreadGas
          spreadLuce
          spreadLuceLordoPerdite
          quotaAlfaSmc
        }
        tab {
          value
        }
        promotion {
          value
        }
        productImage {
          node {
            gatsbyImageData(width: 660)
          }
          value {
            description
          }
        }
        productName {
          value
        }
        crmProdId {
          value
        }
        productDescription {
          value
        }
        productFeatures {
          value
        }
        label {
          value
          content {
            labelColor {
              value
            }
            labelIcon {
              value {
                contentUrl
              }
            }
            labelType {
              value
            }
          }
        }
        cta {
          value
          content {
            ctaLink {
              value
            }
            nameProductSimulatore {
              value
            }
            ctaType {
              value
            }
          }
        }
        alertText {
          value
        }
        labelFieldEle {
          value
          content {
            valueFieldEle {
              value
            }
          }
        }
        labelFieldGas {
          value
          content {
            valueFieldGas {
              value
            }
          }
        }
      }
    }
  }
`;
