import React, { Fragment, useMemo } from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import HUBCaratteristicheProdottoDDL from "../elements/HUBCaratteristicheProdottoDDL";
import Cta from "../elements/cta";
import classNames from "classnames";
import Cookies from "js-cookie";
import Icon from "./icon-svg";
import { isFlusso, getProductName } from "../../utility/config-flussi-switch-in";

const HubProdottoCard = ({
  item,
  commodity,
  target,
  hideLabelTutelato = false,
  clienteFromProcessiInself,
  fornituraFromProcessiInself,
  selectedProduct,
}) => {
  const scadenzaValue = useMemo(
    () =>
      item?.alertText?.value?.replace(
        /@dataFineValidita/g,
        item?.prodottoCRM?.dataFineValidita ? item.prodottoCRM.dataFineValidita : ""
      ),
    [item]
  );

  const eleFeatures = useMemo(() => {
    const features = item?.labelFieldEle
      ?.map((element) => ({
        label: element?.value,
        value: element?.content?.valueFieldEle?.value
          ?.replace(
            /@corrispettivoEnergia/g,
            item?.prodottoCRM?.corrispettivoEnergia ? item.prodottoCRM.corrispettivoEnergia : ""
          )
          ?.replace(
            /@corrispettivoF1/g,
            item?.prodottoCRM?.corrispettivoF1 ? item.prodottoCRM.corrispettivoF1 : ""
          )
          ?.replace(
            /@corrispettivoF2/g,
            item?.prodottoCRM?.corrispettivoF2 ? item.prodottoCRM.corrispettivoF2 : ""
          )
          ?.replace(
            /@corrispettivoF3/g,
            item?.prodottoCRM?.corrispettivoF3 ? item.prodottoCRM.corrispettivoF3 : ""
          )
          ?.replace(
            /@spreadLuce/g,
            item?.prodottoCRM?.spreadLuce ? item.prodottoCRM.spreadLuce : ""
          )
          ?.replace(
            /@consumoLuceLordoPerdite/g,
            item?.prodottoCRM?.spreadLuceLordoPerdite ? item.prodottoCRM.spreadLuceLordoPerdite : ""
          )
          ?.replace(/@spreadF1/g, item?.prodottoCRM?.spreadF1 ? item.prodottoCRM.spreadF1 : "")
          ?.replace(/@spreadF2/g, item?.prodottoCRM?.spreadF2 ? item.prodottoCRM.spreadF2 : "")
          ?.replace(/@spreadF3/g, item?.prodottoCRM?.spreadF3 ? item.prodottoCRM.spreadF3 : "")
          ?.replace(
            /@quotaPvolkWh/g,
            item?.prodottoCRM?.quotaPvolkWh ? item.prodottoCRM.quotaPvolkWh : ""
          )
          ?.replace(
            /@quotaPvolF1kWh/g,
            item?.prodottoCRM?.quotaPvolF1kWh ? item.prodottoCRM.quotaPvolF1kWh : ""
          )
          ?.replace(
            /@quotaPvolF2kWh/g,
            item?.prodottoCRM?.quotaPvolF2kWh ? item.prodottoCRM.quotaPvolF2kWh : ""
          )
          ?.replace(
            /@quotaPvolF3kWh/g,
            item?.prodottoCRM?.quotaPvolF3kWh ? item.prodottoCRM.quotaPvolF3kWh : ""
          )
          ?.replace(
            /@quotaAlfakWh/g,
            item?.prodottoCRM?.quotaAlfakWh ? item.prodottoCRM.quotaAlfakWh : ""
          )
          ?.replace(
            /@quotaAlfaF1kWh/g,
            item?.prodottoCRM?.quotaAlfaF1kWh ? item.prodottoCRM.quotaAlfaF1kWh : ""
          )
          ?.replace(
            /@quotaAlfaF2kWh/g,
            item?.prodottoCRM?.quotaAlfaF2kWh ? item.prodottoCRM.quotaAlfaF2kWh : ""
          )
          ?.replace(
            /@quotaAlfaF3kWh/g,
            item?.prodottoCRM?.quotaAlfaF3kWh ? item.prodottoCRM.quotaAlfaF3kWh : ""
          )
          ?.replace(
            /@corrispettivoBiorariaF1/g,
            item?.prodottoCRM?.corrispettivoBiorariaF1
              ? item.prodottoCRM.corrispettivoBiorariaF1
              : ""
          )
          ?.replace(
            /@corrispettivoBiorariaF23/g,
            item?.prodottoCRM?.corrispettivoBiorariaF23
              ? item.prodottoCRM.corrispettivoBiorariaF23
              : ""
          )
          ?.replace(
            /@quotaCommFissaDomesticoLuce/g,
            item?.prodottoCRM?.quotaCommFissaDomesticoLuce
              ? item.prodottoCRM.quotaCommFissaDomesticoLuce
              : ""
          )
          ?.replace(
            /@quotaCommVariabileDomesticoLuce/g,
            item?.prodottoCRM?.quotaCommVariabileDomesticoLuce
              ? item.prodottoCRM.quotaCommVariabileDomesticoLuce
              : ""
          ),
      }))
      .filter((item) => !item.value.includes("@"));

    return features;
  }, [item]);

  const gasFeatures = useMemo(() => {
    const features = item?.labelFieldGas
      ?.map((element) => ({
        label: element?.value,
        value: element?.content?.valueFieldGas?.value
          ?.replace(
            /@componentePrezzoFisso/g,
            item?.prodottoCRM?.componentePrezzoFisso ? item.prodottoCRM.componentePrezzoFisso : ""
          )
          ?.replace(
            /@quotaPvolSmc/g,
            item?.prodottoCRM?.quotaPvolSmc ? item.prodottoCRM.quotaPvolSmc : ""
          )
          ?.replace(/@spreadGas/g, item?.prodottoCRM?.spreadGas ? item.prodottoCRM.spreadGas : "")
          ?.replace(
            /@quotaCommFissaDomesticoGas/g,
            item?.prodottoCRM?.quotaCommFissaDomesticoGas
              ? item.prodottoCRM.quotaCommFissaDomesticoGas
              : ""
          )
          ?.replace(
            /@quotaCommVariabileDomesticoGas/g,
            item?.prodottoCRM?.quotaCommVariabileDomesticoGas
              ? item.prodottoCRM.quotaCommVariabileDomesticoGas
              : ""
          )
          ?.replace(
            /@quotaAlfaSmc/g,
            item?.prodottoCRM?.quotaAlfaSmc ? item.prodottoCRM.quotaAlfaSmc : ""
          ),
      }))
      .filter((item) => !item.value.includes("@"));

    return features;
  }, [item]);

  const handleCta = (ctaValue) => {
    let startStep = 0;
    let openEcommerce = false;

    if (selectedProduct === "VECOS") {
      if (target === "_blank" && clienteFromProcessiInself === "nuovo") {
        openEcommerce = true;
      }
      if (target === "_blank" && clienteFromProcessiInself === "cliente") {
        startStep = 2;
      }
    }

    if (selectedProduct === "ENPFS") {
      if (target === "_blank" && fornituraFromProcessiInself === "gas") {
        if (clienteFromProcessiInself === "cliente") {
          startStep = 3;
        }
        if (clienteFromProcessiInself === "nuovo") {
          openEcommerce = true;
        }
      }
      if (target === "_blank" && fornituraFromProcessiInself !== "gas") {
        startStep = 1;
      }
    }

    if (isFlusso(ctaValue)) {
      const productName = getProductName(ctaValue);
      const fluxEvent = new CustomEvent("start-flusso-switch-in", {
        detail: {
          productName,
          stepProcessiInself: startStep,
          ecommerceProcessiInself: openEcommerce,
          fornituraProcessiInself: fornituraFromProcessiInself,
          clienteProcessiInself: clienteFromProcessiInself,
        },
      });
      console.log(fluxEvent);
      document.dispatchEvent(fluxEvent);
    } else {
      const functionName = ctaValue.slice(ctaValue.indexOf(":") + 1, ctaValue.indexOf("("));
      const formModalEvent = new CustomEvent("show-form-modal", {
        detail: {
          functionName,
        },
      });
      document.dispatchEvent(formModalEvent);
    }
  };

  return (
    <section
      className={classNames("hub-prodotto__card", {
        "hub-prodotto__card--promotion": item?.promotion?.value,
      })}
    >
      <div className="hub-prodotto__head-container">
        {item?.productName?.value && (
          <span className="hub-prodotto__title">{item.productName.value}</span>
        )}
        {item?.alertText?.value && (
          <div
            className="hub-prodotto__date"
            dangerouslySetInnerHTML={{
              __html: scadenzaValue,
            }}
          ></div>
        )}
      </div>
      <div
        className={classNames("hub-prodotto__body-container", {
          "hub-prodotto__body-container--no-label":
            item?.label?.length === 1 && item?.label?.[0]?.value === "",
        })}
      >
        {item?.productImage?.node && (
          <GatsbyImage
            image={getImage(item.productImage.node)}
            alt={item.productImage.value?.description || ""}
            className="hub-prodotto__image"
          />
        )}
        {item?.label && item?.label?.length > 0 && item?.label?.[0]?.value !== "" && (
          <div className="hub-prodotto__label-container">
            {item.label
              .filter((item) =>
                item?.value === "Consigliata per il passaggio al mercato libero"
                  ? !hideLabelTutelato
                  : true
              )
              .map((label, i) => (
                <Fragment key={i}>
                  {i < 3 && label?.value && (
                    <div
                      className={classNames("hub-prodotto__label", {
                        "hub-prodotto__label--last": i === item.label.length - 1,
                      })}
                      style={
                        label?.content?.labelType?.value === "primary"
                          ? { backgroundColor: label?.content?.labelColor?.value, color: "#ffffff" }
                          : {
                              backgroundColor: "#ffffff",
                              color: label?.content?.labelColor?.value,
                              border: `1px solid ${label?.content?.labelColor?.value}`,
                            }
                      }
                    >
                      <div
                        style={{
                          backgroundColor:
                            label?.content?.labelType?.value === "primary"
                              ? "#ffffff"
                              : `${label?.content?.labelColor?.value}`,
                          WebkitMaskBoxImage: `url(${label?.content?.labelIcon?.value?.contentUrl})`,
                        }}
                        className="hub-prodotto__mask"
                      ></div>
                      <svg viewBox="0 0 20 20" className="hub-prodotto__svg-mask">
                        <mask id="mymask">
                          <image
                            id="img"
                            href={label?.content?.labelIcon?.value?.contentUrl}
                            x="0"
                            y="0"
                            height="20px"
                            width="20px"
                          />
                        </mask>
                      </svg>
                      <span>{label.value}</span>
                    </div>
                  )}
                </Fragment>
              ))}
          </div>
        )}
        <div className="hub-prodotto__desc-container">
          {item?.productDescription?.value && (
            <div
              className="hub-prodotto__description"
              dangerouslySetInnerHTML={{
                __html: item.productDescription.value,
              }}
            ></div>
          )}
          {item?.productFeatures?.value && (
            <div className="hub-prodotto__features d-flex flex-wrap">
              {item.productFeatures.value
                .replace(/ /g, "")
                .split(",")
                .map((feature, j) => (
                  <div
                    className="hub-prodotto__feature d-flex justify-content-md-center align-items-center"
                    key={j}
                  >
                    {HUBCaratteristicheProdottoDDL[feature]?.icona && (
                      <div
                        className="hub-prodotto__icon"
                        style={{
                          "--bgimage": `url(${HUBCaratteristicheProdottoDDL[feature].icona})`,
                        }}
                      />
                    )}
                    <div className="hub-prodotto__feature-description">
                      {HUBCaratteristicheProdottoDDL[feature]?.descr}
                    </div>
                  </div>
                ))}
            </div>
          )}
        </div>
        <div className="hub-prodotto__footer">
          <div className="hub-prodotto__commodity-container">
            {(commodity === "dualTab" || commodity === "eleTab") && eleFeatures?.length > 0 && (
              <div className="hub-prodotto__commodity">
                <span className="hub-prodotto__commodity-title">
                  <Icon name="light" cls="hub-prodotto__commodity-icon"></Icon>
                  <span>Luce</span>
                </span>
                {eleFeatures.map((feature, key) => (
                  <div key={key} className="hub-prodotto__commodity-item">
                    <span className="hub-prodotto__commodity-label">{feature.label}</span>
                    <div
                      className="hub-prodotto__commodity-value"
                      dangerouslySetInnerHTML={{ __html: feature.value }}
                    />
                  </div>
                ))}
              </div>
            )}
            {(commodity === "dualTab" || commodity === "gasTab") && gasFeatures?.length > 0 && (
              <div className="hub-prodotto__commodity">
                <span className="hub-prodotto__commodity-title">
                  <Icon name="gas" cls="hub-prodotto__commodity-icon"></Icon>
                  <span>Gas</span>
                </span>
                {gasFeatures.map((feature, key) => (
                  <div key={key} className="hub-prodotto__commodity-item">
                    <span className="hub-prodotto__commodity-label">{feature.label}</span>
                    <div
                      className="hub-prodotto__commodity-value"
                      dangerouslySetInnerHTML={{ __html: feature.value }}
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
          {item?.cta && (
            <div className="hub-prodotto__cta-container">
              {item.cta.map((element, index) =>
                element?.content?.nameProductSimulatore?.value ? (
                  <button
                    key={index}
                    title={element?.value}
                    onClick={() => {
                      Cookies.set(
                        "simulatoreNomeProdotto",
                        element.content.nameProductSimulatore.value
                      );
                      window.open("/simulatore-bolletta");
                    }}
                    className={classNames("hub-prodotto__cta cta-link", {
                      "cta-link--secondary": element?.content?.ctaType?.value === "secondary",
                    })}
                  >
                    {element?.value}
                  </button>
                ) : element?.content?.ctaLink?.value.startsWith("javascript") ? (
                  <button
                    key={index}
                    className={classNames("hub-prodotto__cta cta-link", {
                      "cta-link--secondary": element?.content?.ctaType?.value === "secondary",
                    })}
                    onClick={() => handleCta(element.content.ctaLink.value)}
                  >
                    {element?.value}
                  </button>
                ) : (
                  <Cta
                    cta={element}
                    target={target}
                    key={index}
                    className={classNames("hub-prodotto__cta", {
                      "cta-link--secondary": element?.content?.ctaType?.value === "secondary",
                    })}
                  />
                )
              )}
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default HubProdottoCard;
